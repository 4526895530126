import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Store } from '@ngxs/store';
import { LoggerService } from '../logger/logger.service';
import { environment } from '@env';
import { ErrorHandlingService } from '../error-handling/error-handling.service';

@Injectable({
  providedIn: 'root',
})
export class BackendInterceptor implements HttpInterceptor {
  public defaultHeaders = new HttpHeaders();

  constructor(
    private readonly store: Store,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly loggerService: LoggerService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // handle i18n request as it is and licenses
    if (
      request.url.includes('i18n') ||
      request.url.includes('3rdpartylicenses')
    ) {
      return next.handle(request);
    }

    const params = new HttpParams({
      fromString: request.params.toString(),
    });
    let headers = request.headers ?? this.defaultHeaders;
    headers.set('Access-Control-Allow-Origin', '*');

    const isUserLoggedIn = this.store.selectSnapshot(
      AppStateSelectors.isUserLoggedIn()
    );
    if (isUserLoggedIn) {
      // is user logged in, set bearer token !!BUT not for the registration (POST)!!
      if (!(request.url.match(/(User)$/) && !(request.method == "PUT"))) {
        const idToken = this.store.selectSnapshot(
          AppStateSelectors.getUserToken()
        );
        headers = headers.set('Authorization', `Bearer ${idToken}`);
      }
    }

    request = request.clone({
      headers,
      params,
    });

    if (environment.debug) {
      const requestString = JSON.stringify(request);
      this.loggerService.debug(
        `[${this.constructor.name}] Request send: ${requestString}`
      );
    }

    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        if (environment.debug && response instanceof HttpResponse) {
          const responseString = JSON.stringify(response);
          this.loggerService.debug(
            `[${this.constructor.name}] Response: ${responseString}`
          );
        }
      }),
      catchError(error => this.errorHandler(error, request, next))
    );
  }

  private errorHandler(
    error: HttpErrorResponse,
    request?: HttpRequest<any>,
    next?: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!error.status) {
      this.errorHandlingService.handleVPNError();
      return throwError(
        () =>
          new Error(
            'Es konnte keine Verbindung zum Backenend hergestellt werden. Versuchen Sie es später oder versuchen Sie Ihre VPN auszuschalten'
          )
      );
    }
    if (error.status === 504) {
      this.errorHandlingService.handleGatewayTimeout(error);
    } else if (this.errorHandlingService.handleFailedRequests(error, request)) {
      return of();
    }
    const errMsg =
      error.error?.detail !== undefined
        ? error.error?.detail
        : error.error?.title !== undefined
          ? error.error?.title
          : error.error !== undefined
            ? error.error
            : 'Ein unbekannter Fehler ist aufgetreten. Versuchen Sie es zu einen späteren Zeitpunkt und schalten Sie Ihre VPN aus.';
    return throwError(() => new Error(errMsg));
  }
}
