<form [formGroup]="form">
    <h4>{{ 'registration.company_data' | translate }}</h4>
    <div class="split-pane">
        <div class="split-pane-container">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>{{ 'registration.company_name' | translate }}</mat-label>
                <input tabindex="1"
                       autocomplete="company"
                       formControlName="companyName"
                       matInput
                       type="text"
                />
            </mat-form-field>
            <mat-form-field *ngIf="!userHasCompany" appearance="outline" color="accent">
                <mat-label>{{ 'registration.contact_person_first_name' | translate }}</mat-label>
                <input formControlName="contactPersonFirstname"
                       tabindex="3"
                       matInput
                       type="text"
                />
            </mat-form-field>
            <mat-form-field *ngIf="!userHasCompany" appearance="outline" color="accent">
                <mat-label>{{ 'registration.phone_contact_person' | translate }}</mat-label>
                <input tabindex="5"
                       formControlName="phoneNumberOfContactPerson"
                       matInput
                       type="text"
                />
            </mat-form-field>
        </div>
        <div class="split-pane-container">
            <app-address-search-autocomplete [tabOrder]="2"
                    title="{{'registration.company_address' | translate}}"
                                             [isCo2]="true">
            </app-address-search-autocomplete>
            <mat-form-field *ngIf="!userHasCompany" appearance="outline" color="accent">
                <mat-label>{{ 'registration.contact_person_last_name' | translate }}</mat-label>
                <input formControlName="contactPersonLastname"
                       tabindex="4"
                       matInput
                       type="text"
                />
            </mat-form-field>
            <mat-form-field *ngIf="!userHasCompany" appearance="outline" color="accent">
                <mat-label>{{ 'registration.role_contact_person' | translate }}</mat-label>
                <input formControlName="roleOfContactPerson"
                       tabindex="6"
                       matInput
                       [pattern]="specialCharsPattern"
                       type="text"
                />
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!userHasCompany" class="split-pane role-pane">
        <div class="split-pane-container">
            <app-co2-company-role></app-co2-company-role>
        </div>
    </div>
</form>
