import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AlternativeTransportEnum,
  Co2FacilityForm,
  Co2YearFormGroup,
  CustomerDeclaration,
  EmissionType,
  RegisterCo2FacilityForm,
} from '../../interfaces/register_location';
import { AppStateSelectors } from '@appState/app-state.selector';
import { RegistrationService } from '@registration/services/registration.service';
import { FacilityCo2Type } from '@enums';
import {
  FacilityService,
  TGetCO2FacilityDTO,
  TPostFacilityAndPlannedCO2AmountDTO,
  TPutFacilityAndPlannedCO2AmountDTO,
} from '@api';
import { ErrorHandlingService } from '@services';
import { validateExtrenumValue } from '../../../../shared/validators/validate-value-to-number';
import {
  LoadFacilityList,
  LoadSelectedFacilityById,
  SetSelectedFacilityId,
} from '@appState/app.actions';
import { DashboardService } from '@pages/dashboard/services/dashboard.service';
import {
  maxBioRatio,
  maxCharacterLength,
  maxFirstYear,
  maxHours,
  maxLengthDescription,
  minCharacterLength,
  minFirstYear,
  specialChars,
} from '../../../../shared/constants/form-constants';

@UntilDestroy()
@Component({
  selector: 'app-register-co2-location',
  templateUrl: './register-co2-location.component.html',
  styleUrls: ['./register-co2-location.component.scss'],
})
export class RegisterCo2LocationComponent implements OnInit, OnDestroy {
  public currentPage: 1 | 2 = 1;
  public form: FormGroup<RegisterCo2FacilityForm>;
  public isReadonly = false;
  public years: number[] = [2030, 2035, 2040, 2045];
  public step = 0;
  public allSteps = 4;
  public errors: string[] = [];
  public isSaving = false;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isUserCo2Customer$: Observable<boolean>;
  public facility: TGetCO2FacilityDTO;
  @Select(AppStateSelectors.getSelectedFacility())
  private readonly facility$: Observable<TGetCO2FacilityDTO>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<RegisterCo2LocationComponent>,
    private readonly facilityService: FacilityService,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly dashboardService: DashboardService
  ) {}

  public get facilityForm(): FormGroup<Co2FacilityForm> {
    return this.form.controls.facility as FormGroup;
  }

  public get alternativeTransportTypeIdControl(): FormControl {
    return this.form.controls.alternativeTransportTypeId;
  }

  public get alternativeTransportControl(): FormControl {
    return this.form.controls.alternativeTransport;
  }

  public get descriptionControl(): FormControl {
    return this.form.controls.description;
  }

  public get typeIdControl(): FormControl {
    return this.facilityForm.controls.typeId;
  }

  public get valueForm(): FormArray<FormGroup<Co2YearFormGroup>> {
    if (this.typeIdControl.value == FacilityCo2Type.Einspeisung) {
      return this.form.controls.produce_list as FormArray;
    } else if (this.typeIdControl.value == FacilityCo2Type.Ausspeisung) {
      return this.form.controls.consume_list as FormArray;
    } else {
      return new FormArray<FormGroup<Co2YearFormGroup>>([
        ...this.form.controls.produce_list.controls,
        ...this.form.controls.consume_list.controls,
      ]);
    }
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      facility: this.formBuilder.group({
        typeId: new FormControl(FacilityCo2Type.Einspeisung, [
          Validators.required,
          Validators.minLength(minCharacterLength),
          Validators.maxLength(maxCharacterLength),
        ]),
        facilityName: new FormControl('', [
          Validators.required,
          Validators.minLength(minCharacterLength),
          Validators.maxLength(maxCharacterLength),
          Validators.pattern(specialChars),
        ]),
        firstYearOfTransportCapability: new FormControl(null, [
          Validators.required,
          Validators.min(minFirstYear),
          Validators.max(maxFirstYear),
        ]),
        emissionTypeId: new FormControl(EmissionType.None),
        companyAddress: this.registrationService.buildAddressForm(true),
      }),
      consume_list: this.formBuilder.array<FormGroup<Co2YearFormGroup>>(
        this.years.map(year => this.buildFormGroupForYears(year))
      ),
      produce_list: this.formBuilder.array<FormGroup<Co2YearFormGroup>>(
        this.years.map(year => this.buildFormGroupForYears(year))
      ),
      alternativeTransportTypeId: new FormControl<AlternativeTransportEnum[]>(
        [],
        [Validators.required]
      ),
      alternativeTransport: new FormControl<string>(null, [
        Validators.minLength(minCharacterLength),
        Validators.maxLength(maxCharacterLength),
        Validators.pattern(specialChars),
      ]),
      customerDeclaration: new FormControl(null, [
        Validators.minLength(minCharacterLength),
        Validators.maxLength(maxCharacterLength),
        Validators.pattern(specialChars),
      ]),
      customerDeclarationTypeId: new FormControl(CustomerDeclaration.NONE),
      transportTarget: new FormControl(null, [
        Validators.minLength(minCharacterLength),
        Validators.maxLength(maxCharacterLength),
        Validators.pattern(specialChars),
      ]),
      description: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(minCharacterLength),
        Validators.maxLength(maxLengthDescription),
      ]),
      sharingWithAuthorities: new FormControl<boolean>(false),
      sharingWithThirdParty: new FormControl<boolean>(false),
    });

    this.facility$
      .pipe(untilDestroyed(this))
      .subscribe((loadedFacility: TGetCO2FacilityDTO) => {
        if (loadedFacility) {
          this.isReadonly = true;
          this.form.disable();
          this.facility = loadedFacility;
          this.fillFormWithFacilityData(loadedFacility);
        } else {
          this.store.dispatch(new LoadSelectedFacilityById());
        }
      });
  }

  public ngOnDestroy(): void {
    // clean selected facility
    this.store.dispatch(new SetSelectedFacilityId(null));
  }

  public changeIsReadonly(): void {
    this.isReadonly = !this.isReadonly;
    this.form.enable();
  }

  public nextStep() {
    this.step++;
  }

  public onCreate(): void {
    this.isSaving = true;
    if (!!this.facility) {
      const co2_facility = this.mapRegisterLocationToApiModel(this.form.value);
      this.facilityService
        .apiFacilityPut({
          co2_facility,
        })
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.dialogRef.close();
            this.store.dispatch(new LoadFacilityList());
          },
          error: err => {
            this.errorHandlingService.showError(err);
            this.isSaving = false;
          },
        });
    } else {
      this.facilityService
        .apiFacilityPost({
          co2_facility: this.mapRegisterLocationToApiModel(this.form.value),
        })
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.dialogRef.close();
            this.dashboardService.initDashboard();
          },
          error: err => {
            this.errorHandlingService.showError(err);
            this.isSaving = false;
          },
        });
    }
  }

  public checkFormValidationOfCurrentStep(step: number): boolean {
    switch (step) {
      case 0:
        return this.facilityForm.valid;
      case 1:
        return this.valueForm.valid;
      case 2:
        if (this.alternativeTransportTypeIdControl?.value?.length > 0) {
          if (
            this.alternativeTransportTypeIdControl?.value?.includes(
              AlternativeTransportEnum.FREITEXT
            )
          ) {
            return (
              this.alternativeTransportControl.valid &&
              this.form.controls.customerDeclaration.valid
            );
          }
        }
        return (
          this.alternativeTransportTypeIdControl.valid &&
          this.form.controls.customerDeclaration.valid
        );
      case 3:
        return this.descriptionControl.valid;
      default:
        return false;
    }
  }

  private mapRegisterLocationToApiModel(
    form
  ): TPutFacilityAndPlannedCO2AmountDTO | TPostFacilityAndPlannedCO2AmountDTO {
    const payload = {
      facility_type_id: form.facility.typeId,
      facility_name: form.facility.facilityName,
      facility_first_year_of_transport_capability:
        form.facility.firstYearOfTransportCapability,
      facility_emission_type_id: form.facility.emissionTypeId,
      facility_customer_declaration: form.customerDeclaration,
      facility_customer_declaration_typ_id: form.customerDeclarationTypeId,
      facility_transport_target: form.transportTarget,
      facility_street: form.facility.companyAddress.street,
      facility_street_number: form.facility.companyAddress.streetNumber,
      facility_zip_code: form.facility.companyAddress.zipCode,
      facility_city: form.facility.companyAddress.city,
      //needs to be switch cause of be
      facility_latitude: form.facility.companyAddress.lng,
      facility_longitude: form.facility.companyAddress.lat,
      facility_alternative_selection: form.alternativeTransportTypeId.reduce(
        (cur: number, next: number) => cur + next,
        0
      ),
      facility_alternative_text: form.alternativeTransport,
      facility_plan: {
        consume_list:
          this.typeIdControl.value == FacilityCo2Type.Ausspeisung ||
          this.typeIdControl.value == FacilityCo2Type.Hybrid
            ? form.consume_list
            : null,
        produce_list:
          this.typeIdControl.value == FacilityCo2Type.Einspeisung ||
          this.typeIdControl.value == FacilityCo2Type.Hybrid
            ? form.produce_list
            : null,
      },
      facility_description: form.description,
      facility_data_sharing_with_authority_consent: form.sharingWithAuthorities,
      facility_data_sharing_with_third_part_consent: form.sharingWithThirdParty,
    };
    if (this.facility != null) {
      payload['facility_id'] = this.facility.facility_id;
    }
    return payload;
  }

  private getSelectedOptions<T extends number>(
    enumObj: any,
    value: T
  ): number[] {
    const selectedOptions: number[] = [];

    for (const key in enumObj) {
      const enumValue = enumObj[key];

      if (typeof enumValue === 'number' && (value & enumValue) === enumValue) {
        selectedOptions.push(enumValue);
      }
    }

    if (selectedOptions.length > 1 && selectedOptions.includes(0)) {
      selectedOptions.splice(0, 1);
    }

    return selectedOptions;
  }

  private buildFormGroupForYears(year: number): FormGroup<Co2YearFormGroup> {
    return this.formBuilder.group<Co2YearFormGroup>({
      year: new FormControl(year),
      hour: new FormControl(0, [
        validateExtrenumValue('min', 0),
        validateExtrenumValue('max', maxHours),
      ]),
      ton: new FormControl(0, [validateExtrenumValue('min', 0)]),
      bio_ratio: new FormControl(0, [
        Validators.min(0),
        Validators.max(maxBioRatio),
        Validators.pattern('(^100)|(^\\d{0,2}(,\\d{0,2})?)|(0)$'),
      ]),
    });
  }

  private fillFormWithFacilityData(loadedFacility: TGetCO2FacilityDTO) {
    this.form.patchValue({
      facility: {
        typeId: loadedFacility.facility_type_id,
        facilityName: loadedFacility.facility_name,
        firstYearOfTransportCapability:
          loadedFacility.facility_first_year_of_transport_capability,
        emissionTypeId: loadedFacility.facility_emission_type_id,
        companyAddress: {
          address: `${loadedFacility.facility_street} ${loadedFacility.facility_street_number} ${loadedFacility.facility_zip_code} ${loadedFacility.facility_city}`,
          street: loadedFacility.facility_street,
          streetNumber: loadedFacility.facility_street_number,
          zipCode: loadedFacility.facility_zip_code,
          city: loadedFacility.facility_city,
          lat: loadedFacility.facility_latitude,
          lng: loadedFacility.facility_longitude,
        },
      },
      consume_list: loadedFacility.facility_plan?.co2_consume_list?.map(
        item => ({
          year: item.year,
          ton: Number(item.ton),
          bio_ratio: Number(item.bio_ratio),
          hour: Number(item.hour),
        })
      ),
      produce_list: loadedFacility.facility_plan?.co2_produce_list?.map(
        item => ({
          year: item.year,
          ton: Number(item.ton),
          bio_ratio: Number(item.bio_ratio),
          hour: Number(item.hour),
        })
      ),
      alternativeTransportTypeId: this.getSelectedOptions(
        AlternativeTransportEnum,
        loadedFacility.facility_alternative_selection
      ),
      alternativeTransport: loadedFacility.facility_alternative_text,
      customerDeclaration: loadedFacility.facility_customer_declaration,
      customerDeclarationTypeId:
        loadedFacility.facility_customer_declaration_typ_id,
      transportTarget: loadedFacility.facility_transport_target,
      description: loadedFacility.facility_description,
      sharingWithAuthorities:
        loadedFacility.facility_data_sharing_with_authority_consent,
      sharingWithThirdParty:
        loadedFacility.facility_data_sharing_with_third_part_consent,
    });
  }
}
